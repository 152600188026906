/* istanbul ignore file */
import styled from 'styled-components';

export const CustomerSentimentStyle = styled.section`
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: hidden;
	font-size: 3vh
`;

export const TitleStyle = styled.h1`
	height: fit-content;
	font-size: 1.5em;
	text-transform: capitalize;
	padding-top: 1.5em;
	padding-left: 2.5%;
	margin: 0;
	margin-bottom: 1em;
	font-weight: 700;
	color: #4E98DD;
`;

export const SectionsContainerStyle = styled.div`
    display: flex;
    flex-direction: column;
	width: 100%;
	height: 100%;
`;

export const LeftSectionContainerStyle = styled.div`
    display: flex;
    flex-direction: row;
	width: 100%;
	height: 45%;
    gap: 1vh;
`;

export const HeaderContainerStyle = styled.div`
    display: flex;
	justify-content: space-between;
`;

export const StyledConstants = {
	CustomerSentimentStyle,
	TitleStyle,
	SectionsContainerStyle,
	LeftSectionContainerStyle,
	HeaderContainerStyle,
};


/* istanbul ignore file */
import { useEffect, useState } from 'react'

function getWindowSize() {
	const { innerWidth, innerHeight } = window;
	return { innerWidth, innerHeight };
};

export const useWindowsSize = () => {
    const [{ innerHeight, innerWidth }, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

  return {innerHeight, innerWidth}
}

export const getApiUrl = () => window.ENV_CONFIG["sentiment-pod-module"].sentimentApiUrl;
export const getUseProxyReverse = () => window.ENV_CONFIG["sentiment-pod-module"].useReverseProxy;
export const getIsActivatedFeedbackLoop = () => window.ENV_CONFIG["sentiment-pod-module"].isActivatedFeedbackLoop;
export const getSSOToken = () => sessionStorage.getItem('ssoAccessToken') ? sessionStorage.getItem('ssoAccessToken') : '';
export const getCompanyID = () => sessionStorage.getItem('companyId') ? sessionStorage.getItem('companyId') : '';

export const COMMON_COLOR = {
    darkRed: '#d6464d',
    darkGreen: '#2a945f',
    darkYellow: '#f4d949',
}

export const FAKE_DATA = [
    {
        "partnerId": "p48382",
        "companyId": "cwtest",
        "ticketId": "598",
        "sentimentScore": {
            "neutral": 0.2,
            "negative": 0.5,
            "positive": 0.3
        },
        "notesScores": [
            {
                "noteId": "vmti6jf",
                "sentimentScore": {
                "neutral": 0.12,
                "negative": 0.22,
                "positive": 0.66,
                },
                "updatedAt": "2022-10-11T07:16:02.967Z"
            },
            {
                "noteId": "noro5jr",
                "sentimentScore": {
                "neutral": 0.22,
                "negative": 0.32,
                "positive": 0.46,
                },
                "updatedAt": "2022-10-15T07:11:01.967Z"
            },
            {
                "noteId": "noda5jr",
                "sentimentScore": {
                "neutral": 0.2,
                "negative": 0.5,
                "positive": 0.3,
                },
                "updatedAt": "2022-10-20T07:11:01.967Z"
            },
        ]
    },
    {
        "partnerId": "p48382",
        "companyId": "cwtest",
        "ticketId": "597",
        "sentimentScore": {
            "neutral": 0.20,
            "negative": 0.30,
            "positive": 0.50
        },
        "notesScores": [
            {
                "noteId": "vmti6jf",
                "sentimentScore": {
                    "neutral": 0.12,
                    "negative": 0.22,
                    "positive": 0.66,
                },
                "updatedAt": "2022-10-11T07:16:02.967Z"
            },
            {
                "noteId": "noda5jr",
                "sentimentScore": {
                "neutral": 0.30,
                "negative": 0.20,
                "positive": 0.50,
                },
                "updatedAt": "2022-10-20T07:11:01.967Z"
            },
        ]
    },
    {
        "partnerId": "p48382",
        "companyId": "cwtest",
        "ticketId": "596",
        "sentimentScore": {
            "neutral": 0.0,
            "negative": 0.0,
            "positive": 0.0
        },
        "notesScores": [
            {
                "noteId": "noro5jr",
                "sentimentScore": {
                "neutral": 0.0,
                "negative": 0.0,
                "positive": 0.0,
                },
                "updatedAt": "2022-10-15T07:11:01.967Z"
            },
            {
                "noteId": "vmti6jf",
                "sentimentScore": {
                "neutral": 0.10,
                "negative": 0.20,
                "positive": 0.70,
                },
                "updatedAt": "2022-10-11T07:16:02.967Z"
            },
            {
                "noteId": "noro5jr",
                "sentimentScore": {
                "neutral": 0.15,
                "negative": 0.5,
                "positive": 0.80,
                },
                "updatedAt": "2022-10-15T07:11:01.967Z"
            },
            {
                "noteId": "noda5jr",
                "sentimentScore": {
                "neutral": 0.5,
                "negative": 0.5,
                "positive": 0.90,
                },
                "updatedAt": "2022-10-20T07:11:01.967Z"
            },
        ]
    },
    {
        "partnerId": "p48382",
        "companyId": "cwtest",
        "ticketId": "595",
        "sentimentScore": {
            "neutral": 0.60,
            "negative": 0.5,
            "positive": 0.35
        },
        "notesScores": [
            {
                "noteId": "vmti6jf",
                "sentimentScore": {
                "neutral": 0.60,
                "negative": 0.05,
                "positive": 0.35,
                },
                "updatedAt": "2022-10-11T07:16:02.967Z"
            },
        ]
    },
    {
        "partnerId": "p48382",
        "companyId": "cwtest",
        "ticketId": "594",
        "sentimentScore": {
            "neutral": 0.10,
            "negative": 0.05,
            "positive": 0.85
        },
        "notesScores": [
            {
                "noteId": "noro5jr",
                "sentimentScore": {
                "neutral": 0.15,
                "negative": 0.5,
                "positive": 0.80,
                },
                "updatedAt": "2022-10-15T07:11:01.967Z"
            },
            {
                "noteId": "noda5jr",
                "sentimentScore": {
                "neutral": 0.5,
                "negative": 0.5,
                "positive": 0.90,
                },
                "updatedAt": "2022-10-20T07:11:01.967Z"
            },
            {
                "noteId": "noda5jr",
                "sentimentScore": {
                "neutral": 0.5,
                "negative": 0.5,
                "positive": 0.90,
                },
                "updatedAt": "2022-10-20T07:11:01.967Z"
            },
            {
                "noteId": "noda5jr",
                "sentimentScore": {
                "neutral": 0.10,
                "negative": 0.20,
                "positive": 0.70,
                },
                "updatedAt": "2022-10-20T07:11:01.967Z"
            },
            {
                "noteId": "noda5jr",
                "sentimentScore": {
                "neutral": 0.10,
                "negative": 0.05,
                "positive": 0.85,
                },
                "updatedAt": "2022-10-20T07:11:01.967Z"
            },
        ]
    },
]


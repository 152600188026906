import { useSelector } from "react-redux";
import { selectColorByScore } from "../../../shared/helpers";
import { StyledConstants } from "./styled-constants";

const LastSentimentScore = () => {
    const { sentimentScoreData: { overalls } } = useSelector(state => state.customerSentiment);
    const lastScore = overalls && overalls.length > 0 ? overalls[overalls.length - 1] : 0;

    return (
        <div style={{ minHeight: '70px' }}>
            {<StyledConstants.LastSentimentScoreStyle className="customer-sentiment__last-sentiment-score" color={selectColorByScore(lastScore)}>
                {lastScore}
            </StyledConstants.LastSentimentScoreStyle>}
        </div>
    );
}


export default LastSentimentScore;
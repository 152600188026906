import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import App from './App';
import { store } from './config';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

try {
  fetch('./src/env-config.json')
    .then(response => response.json())
    .then(data => {
      window.ENV_CONFIG = data;
      render();
    })
    .catch(() => {
      console.log(`ENV file not found at public/src/env-config.json`);
    });
} catch (error) {
  console.log(`ENV file not found at public/src/env-config.json`);
}

const render = () => {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );  
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* istanbul ignore file */
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { data } from './data/data';
import { StyledConstants } from './constants';
import { useSelector } from "react-redux";
import { useWindowsSize } from '../../../shared/hooks';
import { selectColorByScore } from '../../../shared/helpers';

ChartJS.register(ArcElement, Tooltip, Legend);


const OverallSentiment = () => {

	const { overallData: {positive, negative, neutral} } = useSelector(state => state.customerSentiment);
	const { innerHeight } = useWindowsSize();

	const selectFontSize = () => {
		const newFontSize = innerHeight * 0.015;
		return newFontSize > 10 ? newFontSize < 20 ? newFontSize : 20 : 10;
	}

	return (
		<StyledConstants.OverallSentimentStyle className="customer-sentiment__overall-sentiment">
			<StyledConstants.PieLabelContainerStyle className="customer-sentiment__overall-sentiment__legend">
				<StyledConstants.PieBigLabelStyle className="customer-sentiment__overall-sentiment__positive" color={selectColorByScore(positive)}>{positive}</StyledConstants.PieBigLabelStyle>
				<StyledConstants.PieSmallLabelStyle>100</StyledConstants.PieSmallLabelStyle>
			</StyledConstants.PieLabelContainerStyle>
			<StyledConstants.PieChartContainerStyle className="customer-sentiment__overall-sentiment__pie-chart">
				<Pie
					data={data(positive,negative,neutral)}
					height='150px'
					options={{
						maintainAspectRatio: false,
						responsive: true,
						plugins: {
							legend: {
								position: 'right',
								labels: {
									color: '#666666',
									boxWidth: selectFontSize()*1.5,
									font: {
										size: selectFontSize()
									}
								}
							}
						}
					}}
				/>
			</StyledConstants.PieChartContainerStyle>
		</StyledConstants.OverallSentimentStyle>
	);
};

export default OverallSentiment;

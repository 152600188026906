/* istanbul ignore file */
import styled from 'styled-components';

const LastSentimentScoreStyle = styled.div`
	color: ${props => props.color};
	margin-right: 77px;
	font-size: 50px;
`;

export const StyledConstants = {
    LastSentimentScoreStyle,
};
/* istanbul ignore file */
import styled from 'styled-components';

const OverallSentimentStyle = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 1rem;
`;

const PieChartContainerStyle = styled.div`
width: 40%;
height: 100%;
fontSize: 1em;
margin-top: -1rem;

@media (max-height: 500px) {
		width: 50%;
	}
`;

const PieLabelContainerStyle = styled.div`
	width: 15%;
	display: flex;
	flex-direction: column;
	margin-right: 4em;
	margin-top: -1rem;
`;

const PieBigLabelStyle = styled.label`
	width: 100%;
	font-size: 3em;
	text-align: center;
	color: ${props => props.color};
	border-bottom: 0.05em solid #999999;
`;

const PieSmallLabelStyle = styled.label`
	width: 100%;
	font-size: 3em;
	margin-top: 0.3em;
	text-align: center;
	color: #999999;
`;

export const StyledConstants = {
	PieSmallLabelStyle,
	PieBigLabelStyle,
	PieLabelContainerStyle,
	PieChartContainerStyle,
	OverallSentimentStyle,
};
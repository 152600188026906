import './App.css';
import React from 'react';
import CustomerSentiment from './customer-sentiment/customer-sentiment';
import { ThemeProvider } from '@cw-ui/styles';

const App = () => {
  return (
    <ThemeProvider theme="blue">
      <CustomerSentiment />
    </ThemeProvider>
  );
}

export default App;

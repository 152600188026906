import { useEffect, useState } from "react"
import PropTypes from 'prop-types';

const defaultInputParam = {
    "partnerId": "",
    "companyId": "",
    "ticketId": "",
    "sentimentScore": {
        "neutral": 0,
        "negative": 0,
        "positive": 0
    },
    "notesScores": [
    ]
}

const initialState = {
    "partnerId": "",
    "companyId": "",
    "ticketId": "",
    "overallData": {
        "neutral": 0,
        "negative": 0,
        "positive": 0
    },
    "sentimentScoreData": {
        "dates": [],
        "positives": [],
        "negatives": [],
        "neutrals": [],
        "overalls": [],
        "notesIDs": [],
        "noteTexts": [],
        "votes": [],
    },
}

const MAXIMUM_NEGATIVE_POSITIVE_INCREASE_VALUE = 0.4;
const MAXIMUM_NEUTRAL_INCREASE_VALUE = 0.2;
const MAXIMUM_NEGATIVE_VALUE = 0.4;
const MAXIMUM_NEUTRAL_VALUE = 0.6;

const useDataSentimentConverter = (initialData = defaultInputParam) => {
    const [data, setDataInformation] = useState(initialData)
    const [formattedData, setFormattedData] = useState(initialState);

    const percentageFormater = (number) => Math.round(number * 100);
    const dateFormatter = (newDate) => {
        const paddedNumber = (number) => number.toString().padStart(2, '0');
        return `${newDate.getFullYear()}-${paddedNumber(newDate.getMonth() + 1)}-${paddedNumber(newDate.getDate())} - ${newDate.getHours()}:${paddedNumber(newDate.getMinutes())}`;
    }

    /**
     * returns a value depending on the highest number within the scores of the notes and the mathematical formula associated with it.
     * 
     *
     * The formulas are:
     * - negative: if the highest score is negative, the maximum possible negative value is taken and subtracted from the highest value of the score multiplied by the maximum value that can increase.
     * neutral: if the highest score is neutral, the highest score of the note is taken and multiplied by the maximum neutral increment value and the result is added to the maximum possible negative value.
     * - positive: if the highest score is positive, the highest score of the note is taken and multiplied by the maximum positive increment value and the result is added to the maximum possible neutral value.
     *
     * The smallest value in the range is inclusive
     * The highest value in the range is exclusive
     * 
     * ranges are:
     * - [0 - 40) negative
     * - [40 - 60) neutral
     * - [60 - 100) positive
     * 
     * That is why the maximum possible increment is 40% (0.4) for positive and negative, and for neutral it is 20% (0.2).
     *
     * @param {number} negative Negative value in the note selected, e.g (0.9).
     * @param {number} neutral Neutral value in the note selected, e.g (0.9).
     * @param {number} positive Positive value in the note selected, e.g (0.9).
     * @return {string} Returns a percentage value taking into account the formulas stipulated above. 
     */
    const handleSetOverall = (positive, negative, neutral) => {
        const newArray = [positive, negative, neutral];
        const arraySorted = newArray.sort();
        const numberToCompare = arraySorted[2];

        if (positive === 0 && negative === 0 && neutral === 0) {
            return 0;
        }

        switch (numberToCompare) {
            case positive:
                return (numberToCompare * MAXIMUM_NEGATIVE_POSITIVE_INCREASE_VALUE) + MAXIMUM_NEUTRAL_VALUE;
            case negative:
                return MAXIMUM_NEGATIVE_VALUE - (numberToCompare * MAXIMUM_NEGATIVE_POSITIVE_INCREASE_VALUE);
            case neutral:
                return (numberToCompare * MAXIMUM_NEUTRAL_INCREASE_VALUE) + MAXIMUM_NEGATIVE_VALUE;
            default:
                return 0;
        };
    }

    const handleDataFormat = () => {
        if (!data) {
            return null;
        }

        const { notesScores } = data;

        const { positive: globalPositiveScore, negative: globalNegativeScore, neutral: globalNeutralScore } = data.sentimentScore

        let newDatesArray = [];
        let newPositiveArray = [];
        let newNegativeArray = [];
        let newNeutralArray = [];
        let newOverallArray = [];
        let newNotesIDsArray = [];
        let newNoteTextsArray = [];
        let newVotesArray = [];

        const newDataReversed = notesScores.reverse();

        for (let i = 0; i < newDataReversed.length; i++) {
            const note = newDataReversed[i];
            const newDate = new Date(note.updatedAt);
            const newDateFormat = dateFormatter(newDate);
            const { positive, negative, neutral } = note.sentimentScore;
            const { noteId, noteText, vote } = note;

            newDatesArray.push(newDateFormat);
            const newOverallValue = handleSetOverall(positive, negative, neutral)
            newPositiveArray.push(percentageFormater(positive));
            newNegativeArray.push(percentageFormater(negative));
            newNeutralArray.push(percentageFormater(neutral));
            newOverallArray.push(percentageFormater(newOverallValue));
            newNotesIDsArray.push(noteId);
            newNoteTextsArray.push(noteText);
            newVotesArray.push(vote);
        };

        const newDataObject = {
            "partnerId": data.partnerId,
            "companyId": data.companyId,
            "ticketId": data.ticketId,
            "overallData": {
                "neutral": percentageFormater(globalNeutralScore),
                "negative": percentageFormater(globalNegativeScore),
                "positive": percentageFormater(globalPositiveScore),
            },
            "sentimentScoreData": {
                "dates": newDatesArray,
                "positives": newPositiveArray,
                "negatives": newNegativeArray,
                "neutrals": newNeutralArray,
                "overalls": newOverallArray,
                "notesIDs": newNotesIDsArray,
                "noteTexts": newNoteTextsArray,
                "votes": newVotesArray,
            },
        }

        setFormattedData(newDataObject);
    }

    useEffect(() => {
        data && handleDataFormat();
    }, [data])


    return {
        formattedData,
        setDataInformation,
    }
}

export default useDataSentimentConverter;

useDataSentimentConverter.propTypes = {
    partnerId: PropTypes.string,
    companyId: PropTypes.string,
    ticketId: PropTypes.string,
    sentimentScore: PropTypes.shape({
        neutral: PropTypes.number.isRequired,
        negative: PropTypes.number.isRequired,
        positive: PropTypes.number.isRequired,
    }),
    notesScores: PropTypes.arrayOf(
        PropTypes.shape({
            updatedAt: PropTypes.string.isRequired,
            sentimentScore: PropTypes.PropTypes.shape({
                neutral: PropTypes.number.isRequired,
                negative: PropTypes.number.isRequired,
                positive: PropTypes.number.isRequired,
            }),
            processedAt: PropTypes.string.isRequired,
            noteId: PropTypes.number.isRequired,
            noteText: PropTypes.string.isRequired,
            vote: PropTypes.string.isRequired,
        }),
    ),
};

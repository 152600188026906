/* istanbul ignore file */
import styled from 'styled-components';

const SentimentScoreHistoryStyle = styled.section`
	width: 100%;
	height: 100%;
`;

const SentimentDropdownStyle = styled.section`
	padding: 3em 3em;
	padding-bottom: 0;
	display: flex;
	justify-content: flex-end;
`;

const SelectDataTypeStyle = styled.select`
  width: auto;
  position: absolute;
  right: 3%;
  border-radius: 4px;
  color: gray;
  padding-left: 5px;
  border: 1px solid rgb(204, 204, 204);
  font-size: ${props => props.fontSize};
  :hover {
    border-color: rgb(81, 152, 176);
    color: rgb(81, 152, 176);
}
  option {
    color: gray;
    background: white;
    display: flex;
	font-size: 1em;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const StyledConstants = {
	SentimentScoreHistoryStyle,
	SentimentDropdownStyle,
	SelectDataTypeStyle
};

import { COMMON_COLOR } from "../../config";


const ranges = [
    {
        lower: 0,
        top: 40,
        color: COMMON_COLOR.darkRed,
    },
    {
        lower: 40,
        top: 60,
        color: COMMON_COLOR.darkYellow,
    },
    {
        lower: 60,
        top: 101,
        color: COMMON_COLOR.darkGreen,
    }
];

/**
 * Returns a suitable color for each range.
 *
 * The ranges are:
 * - 0 to 40: red
 * - 40 to 60: yellow
 * - 60 to 100: green
 *
 * The smallest value in the range is inclusive
 * The highest value in the range is exclusive
 *
 * @param {number} score . e.g: 80 (Positive)
 * @return {string} hex color e.g :#d6464d (Green)
 */
export const selectColorByScore = (score) => {
    for (const range of ranges) {

        if (range.lower <= score && range.top > score) {
            return range.color
        }

    }
    throw new RangeError(`Score out of range: ${score}`);
}
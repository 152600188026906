/* istanbul ignore file */
import React, { useRef, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { data } from './data/data';
import { StyledConstants } from './constants';
import { useWindowsSize } from '../../../shared/hooks';
import { selectColorByScore } from '../../../shared/helpers';
import { useEffect } from 'react';
import SentimentTooltip from './sentiment-tooltip/sentiment-tooltip';
import { getIsActivatedFeedbackLoop } from '../../../config';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const SentimentScoreHistory = () => {
	const IS_ACTIVATED_FEEDBACKLOOP = getIsActivatedFeedbackLoop();
	const { sentimentScoreData: { dates, overalls } } = useSelector(state => state.customerSentiment);
	const { innerHeight, innerWidth } = useWindowsSize();
	const chartRef = useRef(null);
	const tooltipRef = useRef(null);
	const [tooltip, setTooltip] = useState({
		top: 0,
		left: 0,
		dataIndex: 0,
		dataLength: 0,
		label: '',
		formattedValue: 0,
	});
	const trackingContainerID = "customer-sentiment__tracking-container";

	const selectFontSize = () => {
		const newFontSize = innerHeight * 0.008;
		return newFontSize > 8 ? (newFontSize < 20 ? newFontSize : 20) : 8;
	}

	const customPlugin = {
		id: 'customPlugin',
		// Highlight last XAxis element.
		beforeDraw: (chart) => {
			const categoryScale = chart.boxes[2];

			if (!categoryScale || !categoryScale.ticks || !categoryScale.ticks.length > 0) {
				return;
			}

			const length = categoryScale.ticks.length;
			categoryScale.ticks[length - 1].highlighted = true;
		}
	};

	useEffect(() => {
		// Force resizing to prevent blank chart.
		try {
			chartRef.current && chartRef.current.resize();
		} catch (err) {
			throw new Error(`An error occurred: ${err}`);
		}
	}, []);

	/**
	  * Returns a suitable height for each range.
	  *
	  * The ranges are:
	  * - width > 1000 and height < 300: 15% of total width
	  * - width > 1000 and height > 300: 50% of total width
	  * - width < 1000: 30% of total width
	*
	  * all values in the range are exclusive
	  */
	const handleSelectHeight = () => {
		return innerWidth > 1000 ? (innerHeight < 300 ? '30%' : '100%') : '60%';
	}

	const externalTooltipHandler = (context) => {
		const tooltipModel = context.tooltip;
		const position = context.chart.canvas.getBoundingClientRect();
		const dataPoint = !!tooltipModel.dataPoints && tooltipModel.dataPoints[0];
		const left = position.left + tooltipModel.caretX;
		const { dataIndex, label, dataset, formattedValue } = dataPoint;
		const dataLength = dataset && dataset.data ? dataset.data.length : 0;
		const newTooltipData = {
			left,
			top: position.top + tooltipModel.caretY,
			clientHeight: tooltipModel.chart.canvas.clientHeight,
			dataIndex,
			dataLength,
			label,
			formattedValue,
		};

		// Only render tooltip with new data.
		if (tooltip.left !== newTooltipData.left) {
			setTooltip(newTooltipData);
		}

		// Display tooltip.
		if (tooltipRef.current.style.display === 'none') {
			tooltipRef.current.style.display = 'block';
		}
	};

	const handleMouseLeave = () => tooltipRef.current.style.display = 'none';

	return (
		<StyledConstants.SentimentScoreHistoryStyle className="customer-sentiment__score-history">
			<Line
				className="customer-sentiment__score-history__line-chart"
				ref={chartRef}
				data={data(dates, overalls)}
				options={{
					responsive: true,
					layout: {
						padding: {
							bottom: 5,
							left: 20,
							right: innerWidth < 800 ? 60 : 80,
							top: 5,
						},
					},
					hover: {
						mode: 'nearest',
						intersect: true
					},
					plugins: {
						legend: {
							display: false,
						},
						tooltip: {
							enabled: !(IS_ACTIVATED_FEEDBACKLOOP === "true"),
							external: IS_ACTIVATED_FEEDBACKLOOP === "true" && externalTooltipHandler
						}
					},
					scales: {
						x: {
							grid: {
								display: true,
							},
							ticks: {
								padding: 2,
								font: {
									size: selectFontSize(),
									weight: (c) => c.tick && c.tick.highlighted ? 'bolder' : '',
								},
							},
						},
						y: {
							grid: {
								display: true,
							},
							ticks: {
								padding: 10,
								color: (c) => c.tick && selectColorByScore(c.tick.value),
								font: {
									size: selectFontSize(),
									weight: 'bolder',
								}
							},
							suggestedMin: 0,
							suggestedMax: 100,
						},
					},
					elements: {
						point: {
							radius: 5,
							backgroundColor: (c) => c.parsed && selectColorByScore(c.parsed.y),
							pointStyle: 'circle',
						}
					},
					animation: {
						// Create tracking divs for each rating point in the chart.
						onComplete: (ctx) => {
							// This feature is disabled in stg and prod envs.
							if (!(IS_ACTIVATED_FEEDBACKLOOP === "true")) {
								return;
							}

							// Make sure all required data is available.
							if (!ctx || !ctx.chart || !ctx.chart._metasets || !ctx.chart._metasets.length === 0 || !ctx.chart._metasets[0].data) {
								return;
							}

							const container = document.querySelector(`#${trackingContainerID}`);
							const datasets = ctx.chart._metasets[0].data;

							// Clear container.
							if (!!container.firstChild) {
								while (container.firstChild) {
									container.removeChild(container.firstChild);
								}
							}

							// Create tracking divs.
							datasets.forEach((point, i) => {
								const node = document.createElement("div");

								node.id = `customer-sentiment__tracking-div-${i}`;
								node.className = node.id;
								node.style.position = "absolute";
								node.style.top = `${point.y + 70}px`;
								node.style.left = `${point.x}px`;
								node.style.width = "1px";
								node.style.height = "1px";
								node.style.border = "1px solid black";
								node.style.pointerEvents = "none";
								container.appendChild(node);
							});
						}
					}

				}}
				plugins={[customPlugin]}
				height={handleSelectHeight()}
			/>
			<SentimentTooltip ref={tooltipRef} {...tooltip} mouseLeave={handleMouseLeave} />
			<div id={trackingContainerID} />
		</StyledConstants.SentimentScoreHistoryStyle>
	);
};

export default SentimentScoreHistory;

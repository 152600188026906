import axios from "axios";
import { useEffect, useState } from "react";
import { getApiUrl,getSSOToken, getUseProxyReverse } from "../../config";
import useDataSentimentConverter from "./useDataSentimentConverter";
import PropTypes from "prop-types";
import { Buffer } from "buffer";
import * as onReadyObj from"../../../public/src/scripts/custom";
/**
 * Returns an object with JWT token decoded.
 *
 * @param {string} token 'JWT token'
 * @return {object} decoded JWT {parter_id: 'string', ...}
 */
export const decodeJwt = (token) => {
  if (token) {    
    var base64Payload = token.split(".")[1];
    var payloadBuffer = Buffer.from(base64Payload, "base64");
    return JSON.parse(payloadBuffer.toString());
  } else {
    return {};
  }
};

export const useLoadSentimentData = (
  companyId,
  ticketId,
  token,
  dateMark = Date.now(),
  limit = 5,
  order = "desc",
  sortBy = "updatedAt",
  intervalMillisecs = 1000
) => {
  let OnReadyConstructor = new onReadyObj.onReady(); 
  const [refreshToken, setRefreshToken] = useState(false);
  const [rawData, setRawData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);
  const { formattedData: data, setDataInformation } = useDataSentimentConverter();
  const API_URL = getApiUrl();
  const USE_PROXY_REVERSE = getUseProxyReverse();  
  /**
   * Returns partnerID depending on the setting of the environment variable.
   *
   * - If reverse proxy is used it returns 0.
   * - If reverse proxy is not used it returns the partnerID in the JWT token, in case it does not exist, it returns 0.
   *
   * @return {string} partnerID
   */
  const selectPartnerID = () => {
    if (USE_PROXY_REVERSE === "false") {      
      const decodedJWT = decodeJwt(token);             
      return !!token ? decodedJWT["partner_id"] : "0";
    } else {
      return "0";
    }
  };

  const url = `${API_URL}ticket_sentiment/v1/partners/${selectPartnerID()}/ticket/${ticketId}?limit=${limit}&order=${order}&sortBy=${sortBy}&date=${dateMark}&companyId=${companyId}`;
  const fetchData = () => {     
    if (!companyId || !ticketId) {
      return;
    }
    setloading(true);
    const config = {  
      headers: { "sso-token": `${getSSOToken()}` },
    };  
    
    axios
      .get(url, USE_PROXY_REVERSE === "true" ? config : {})
      .then((res) => {
        setRawData(res.data);
        setDataInformation(res.data);
        setError("");
        setRefreshToken(false);
      })
      .catch((err) => {
        setError(err);         
        const oldToken= sessionStorage.getItem('ssoAccessToken');                           
        OnReadyConstructor.refreshToken(); 
        token= sessionStorage.getItem('ssoAccessToken')
        if(oldToken !== token){
          setRefreshToken(true);
        } else {
          setRefreshToken(false);
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (!!companyId && !!ticketId && token !=='') {
      fetchData();
    } else {
      setTimeout(() => {
        fetchData();
      }, intervalMillisecs);
    }
  }, [companyId, ticketId, token]);

  return { data, rawData, error, loading, decodeJwt, selectPartnerID,refreshToken };
};

useLoadSentimentData.propTypes = {
  partnerId: PropTypes.string,
  ticketId: PropTypes.string.isRequired,
  token:PropTypes.string,
  dateMark: PropTypes.number,
  limit: PropTypes.number,
  order: PropTypes.string,
  sortBy: PropTypes.string,
};


/* istanbul ignore file */
import { useEffect, useState } from "react";
import { getCompanyID } from "../../config";

export const useLoadCwParams = () => {
    const searchParams = new URLSearchParams(document.location.search);
	const ticketId = searchParams.get("id");
    const [companyId, setCompanyId] = useState('');

    useEffect(() => {
		function handleChangeStorage() {
			setCompanyId(sessionStorage.getItem('companyId'));
		}

		window.addEventListener('storage', () => handleChangeStorage());
		return () => window.removeEventListener('storage', () => handleChangeStorage());
	}, [])

	useEffect(() => {
		const companyId = getCompanyID();
		companyId && setCompanyId(companyId);
	}, [])

    return { companyId, ticketId }
}

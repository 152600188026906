import styled from 'styled-components';
import { selectColorByScore } from '../../../../../shared/helpers';

const SentimentTooltipStyle = styled.div`
  width: ${props => props.width};
  color: #fff;
  font-size: 10px;
  position: absolute;
  background: rgba(0,0,0,0.8);
  box-sizing: border-box;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
`;

const SentimentTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SentimentHeaderStyle = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SentimentTextStyle = styled.div`
  padding: 5px;
  border: 1px #ddd solid;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; 
  flex: 1;
`;

const SentimentExtendedTextStyle = styled.div`
  flex: 1;
  white-space: pre-wrap;
  padding: 5px;
  border: 1px #ddd solid;
  overflow-y: scroll;
`;

const SentimentButtonsStyle = styled.div`
  text-align: center;
`;

const RatingMarkerStyle = styled.span`
  background-color: ${props => props.value ? selectColorByScore(props.value) : "white"};
  border: 2px #fff solid;
  margin-right: 5px;
  height: 10px;
  width: 10px;
`;

const RatingStyle = styled.div`
  display: flex;
  align-items: center;
`;

const ThumbsStyle = styled.img`
  cursor: pointer;
  filter: ${props => props.rate ? 'invert(19%) sepia(9%) saturate(150%) hue-rotate(170deg) brightness(80%) contrast(150%)' : '' };
`;

export const StyledConstants = {
  SentimentTooltipStyle,
  SentimentTooltipContent,
  SentimentHeaderStyle,
  SentimentTextStyle,
  SentimentExtendedTextStyle,
  SentimentButtonsStyle,
  RatingStyle,
  RatingMarkerStyle,
  ThumbsStyle
};

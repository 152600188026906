import { useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import { StyledConstants } from './constants/styled-constants';
import { useDispatch, useSelector } from 'react-redux';
import { getApiUrl, getCompanyID, getSSOToken, getUseProxyReverse, setData } from '../../../../config';
import { decodeJwt } from '../../../../shared/hooks/useLoadSentimentData';


export const UP_ACTION = 'UP';
export const DOWN_ACTION = 'DOWN';
export const NONE_ACTION = 'NONE';

export const Thumb = (src, rate, action, handleRate) => <StyledConstants.ThumbsStyle className={`customer-sentiment__feedbackloop-action__thumbs__${!!action ? action.toLowerCase() : 'none'}`} rate={rate === action} src={src} onClick={() => handleRate && handleRate(action)} />


export const selectThumbImage = (action) => {
    switch (action) {
        case UP_ACTION:

            return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqUlEQVR4nN3S20uTARjHcbvoIqgucibuVTe3pdKZieIsQxEtUuxAlhFFJ0iovyH6B7ypP6ELxVQ0S00rUzw7Nre2123qZhGRh+ZhU7e9e99vOOtqiJRdRA88tx+e5/c8CQn/Ta31pp5Z7VbPBdqSj/4VUDbraiODWtZ7hNodY4jZidgOzSkWA9KI9tnOsI8Fepx5ozhOgD0T2ap/8ueYp/wu7rMruIpBzAfHcbBmnvp96HPVHqau1jN9HaaugKccJorAmbuK9ZCAOWf3tkhkODtHMudewl3+iKlqEd89mKkB7x2YvIQslhAeM/K9RYO/NZXgO62y3q9XpFHdl6WXqmtx4Hr/4QHJmg+eCpiqhk81MPMQZh7EwKitiEB3FoutqSAWgO8O+O6D5zzSoMYTB4YHsz3R8Vxwl8FkFfhqfk54E1ksI2o7zUqXgYUXQuw4sQg2srVnEuxMeR4HhoYMXyXLcZg4s5nZdDVMX0Z2liDbCpAsufhfG5irT2b9QxrRMR3SkJbVrhQWmxM748EB7ZxkzgJHDorThGzPR7bmIY/ngTMPaeQo800aIkN68FSC9zZM3wDRRLgvvTUOXOtL90WGdZvr2I+hiIUorlJwFYLjCJGhTOab0/G3CIQGjxEeNREZzSE0kLEWfKU+GQcG3wj2UJ+GyIgOedyI4qoEz8VYKw4TwfcGZuvUKFYDuEpg8kJsOmlYy3KzqiIOXGw5+HS5XT0Z6BbCaz1pRMwFyGI5suMcktlIoHsjv1QUW3bs6nhvgbsU2WJwzLck7tvyHxfaD+xfaUvpCHSkEOwSNrtTYLkjg9l6AWlMj2I3otizCPWmsdiYWLwl9qsgYddCo+rxQoPqrb9R5fQ3JXmXXmqC3+rUUdluir0S7nPIFr11tiFp77bgP1U/AKHFBm2Jm62UAAAAAElFTkSuQmCC";

        case DOWN_ACTION:

            return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyElEQVR4nNWTSU9TURhA68KdcWMw0oG2towKKoMIIigRghDnKaJEjIlG4w8wrk2MS5dG414SUFAZrCgNk4WKnWhLy6QbZahMbe3j9b1j2sZFE6OibjzJl9zk5p5801Uo/ktW+9VFwnB+W7ivbClozg/Pt2g+Bdq03fPNm+9+aUm5ufh00/nF1hTj7wsHtR9x74XpKzB2iOWXWgJPdQS7dYRM6nisdKmk5XblPVCs+7lsIG2vaM1ZwFOJ7K5idSiXQJOKpS4DwlsD4nA6kr0EcWg7X81agt0ay0qH6vZSh6rhx0KL7jPuYpi8DFONxM7LHRoWnhuIWrOQ3eXgOwbeWsRhA8KAHqFfR6hbE+VNyoYkWSz9iFk9izMXvJXgqUC0pDP7WEnIZES254CnDPynwFsNzjxwZBONifv1RDq1WUnC0OvUAuyZX2N9Y/oG+I8h2/OYa1IRMmeAqwBGS5AcJURtu5FdxTBahGTbhjCwlVWzrjxJuPxi8y3Zlp7IYvwkeA8g2zKYadIQ7s1BdpUhvi9Fdh+MD4ux6ngVsqOA1cFMgmZddbKwJaVMHNILsUwk5x7E4WzmW9QEWvWII4VEbbH+HYapSzB5KV4BYzXIzlIEyzaCPcZdySW/Ut7BuR0mzsHUVfCUE+nTE3iiR7QWI3tqE3fT1xMxWQ++I8iucgRLHkFrVmqScKV9ywUcGYmGjx9HshWy8FzDYruBqGNf/DET9TB9DT5ch4kG8B2dkZz7eyODuZ0/XpseddGX9rRHc83q5iWT9mH4reG+ZN/pkUcrwFcH42dg8iJMNMr4z97FemW9Yq3gzLPgLgVvVaKH/hMB/KdOr1kUg3eZShzZMqMFiel7Kk2M1akUfwojxor4KjlzwLXrwS//7q8QB7Q10jsD0ntjEJ9xo+JvCZrTUiP9aZHo0NZnfy37TtikLBF6VTv+mXAtfAP+0R6LQi3xGgAAAABJRU5ErkJggg==";

        default:
            return '';
    }
}

export const selectShortText = (noteTexts, dataIndex) => {

    if (!!noteTexts) {
        const newText = noteTexts[dataIndex];
        return (typeof newText === 'string') && newText.length >= 25 ? newText.slice(0, 25) : newText;
    }
    return '';

}

const SentimentTooltip = ({ top, left, clientHeight, dataIndex, label, formattedValue, dataLength, mouseLeave }, ref) => {
    const dispatch = useDispatch();
    const width = 200;
    const defaultHeight = 65;
    const { sentimentScoreData: { notesIDs, noteTexts, votes } } = useSelector(state => state.customerSentiment);
    const dataStored = useSelector(state => state.customerSentiment);
    const [extended, setExtended] = useState(false);
    const [thumbRate, setThumbRate] = useState(NONE_ACTION);
    const [height, setHeight] = useState(defaultHeight);
    const [posTop, setPosTop] = useState();
    const [posLeft, setPosLeft] = useState();
    const USE_PROXY_REVERSE = getUseProxyReverse();


    const shortText = selectShortText(noteTexts, dataIndex);
    const extendedText = noteTexts && noteTexts[dataIndex];

    let extendedHeight = 160;
    const maxHeight = Math.round(clientHeight * .95);
    extendedHeight = extendedHeight > maxHeight ? maxHeight : extendedHeight;

    const handleClick = () => setExtended(!extended);
    const getTopValue = () => top - (height * (1 - formattedValue / 100));
    const getLeftValue = () => {
        switch (dataIndex) {
            case 0: return left - 10;
            case (dataLength - 1): return left - width + 10;
            default: return left - Math.round(width / 2);
        }
    }
    const handleRate = (action) => {

        const newAction = action === thumbRate ? NONE_ACTION : action;

        const memberId = sessionStorage.getItem('memberId');
        const API_URL = getApiUrl();
        const token = getSSOToken();
        console.log("sentiment-Tooltip",token);
        const companyId = getCompanyID();

        const selectPartnerID = () => {
            if (USE_PROXY_REVERSE === 'false') {
                const decodedJWT = decodeJwt(token);    
                return !!token ? decodedJWT["partner_id"] : '0';    
            } else {
                return '0';
            }
        }
    

        const postURL = `${API_URL}ticket_sentiment/v1/partners/${selectPartnerID()}/feedback?companyId=${companyId}`;
        const config = {
            headers: { 'sso-token': `${token}` }
        };

        axios.post(postURL, {
            noteId: notesIDs[dataIndex],
            ticketId: dataStored.ticketId,
            vote: newAction,
            companyId: dataStored.companyId,
            memberId,
        }, USE_PROXY_REVERSE === 'true' ? config : {}).catch((err) => {
            console.error('TEST__', err)
        })

        handleUpdateStore(newAction)

    }

    const handleUpdateStore = (action) => {
        if (votes && dataStored) {
            let newVotesArray = [...votes];
            let newInformation = { ...dataStored };
            let newNoteScore = { ...newInformation.sentimentScoreData, votes: newVotesArray }
            newVotesArray[dataIndex] = action;

            dispatch(setData({ ...newInformation, sentimentScoreData: newNoteScore }));
        }
    }

    const handleMouseLeave = () => {
        setExtended(false);
        mouseLeave();
    }

    useEffect(() => {
        !!votes && setThumbRate(votes[dataIndex]);
    }, [dataIndex, votes])


    useEffect(() => {
        if (left >= 0) {
            setPosTop(getTopValue());
            setPosLeft(getLeftValue());
            setExtended(false);
        }
    }, [left]);

    useEffect(() => {
        setHeight(extended ? extendedHeight : defaultHeight);
    }, [extended]);

    useEffect(() => {
        setPosTop(getTopValue());
    }, [height]);

    return <StyledConstants.SentimentTooltipStyle
        ref={ref}
        style={{ top: posTop, left: posLeft, height, display: 'none' }}
        width={`${width}px`}
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}>
        <StyledConstants.SentimentTooltipContent>
            <StyledConstants.SentimentHeaderStyle>
                <div>{label}</div>
                <div>
                    {
                        extended ? <>
                            {
                                Thumb(selectThumbImage(UP_ACTION), thumbRate, UP_ACTION, handleRate)
                            }
                            {
                                Thumb(selectThumbImage(DOWN_ACTION), thumbRate, DOWN_ACTION, handleRate)
                            }
                        </> : thumbRate !== NONE_ACTION && Thumb(selectThumbImage(thumbRate), thumbRate, thumbRate)
                    }
                </div>
                <StyledConstants.RatingStyle>
                    <StyledConstants.RatingMarkerStyle value={formattedValue} />
                    <div><p>{`${formattedValue} %`}</p></div>
                </StyledConstants.RatingStyle>
            </StyledConstants.SentimentHeaderStyle>
            {!extended && <StyledConstants.SentimentTextStyle>
                {shortText}
            </StyledConstants.SentimentTextStyle>}
            {extended && <StyledConstants.SentimentExtendedTextStyle>
                {extendedText}
            </StyledConstants.SentimentExtendedTextStyle>}
        </StyledConstants.SentimentTooltipContent>
    </StyledConstants.SentimentTooltipStyle>
}

export default forwardRef(SentimentTooltip);
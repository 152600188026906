/* istanbul ignore file */
import styled from 'styled-components';

const SentimentRatingStyle = styled.section`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const LikerContainerStyle = styled.div`
	width: 90%;
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
`;

const EmojisContainerStyle = styled.div`
	width: 90%;
	display: flex;
	flex-direction: row;
`;

const EmojiSectionStyle = styled.div`
	width: ${props => props.width};
	display: flex;
	justify-content: center;
`;

const EmojiStyle = styled.img`
	width: 2.5em;
	margin-top: 0.5em;
`;

const LikerRatingStyle = styled.div`
	width: 80%;
	border-radius: 50px;
	height: 1.5em;
	display: flex;
	flex-direction: row;
`;

const LikerLabelStyle = styled.legend`
	width: 10%;
	font-weight: 400;
	font-size: 1.8em;
	text-align: center;
	color: ${props => props.color}
`;

const LikerIndicatorStyle = styled.div`
	position: absolute;
	width: 2%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 2em;
	font-weight: 60;
	text-align: center;
	margin-top: -1.5em;
	margin-left: ${props => `${(props.ml * 0.275)}%`};
`;

const LikerIndicationNumberStyle = styled.legend`
	color: ${props => props.color}
`;

const LikerDividerStyle = styled.div`
	margin-left: 0.55em;
`;

const ColorPercentageStyle = styled.div`
	width: ${props => props.width};
	border-radius: ${props => props.borderRadius};
	height: 2em;
	background-color: ${props => props.bgColor}
`;


export const StyledConstants = {
	ColorPercentageStyle,
	LikerContainerStyle,
	LikerDividerStyle,
	LikerIndicationNumberStyle,
	LikerIndicatorStyle,
	LikerLabelStyle,
	LikerRatingStyle,
	EmojiStyle,
	EmojiSectionStyle,
	EmojisContainerStyle,
	SentimentRatingStyle
};

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SectionTitle = ({ title }) => {
	return <SectionTitleStyle className="customer-sentiment__main-title" aria-label={`tittle ${title}`}>{title}</SectionTitleStyle>;
};

const SectionTitleStyle = styled.h3`
text-transform: capitalize;
font-size: 1em;
padding-left: 10px;

@media (max-height: 500px) {
		font-size: 1.3em;
		margin-bottom: 1em;
	}
`;

export default SectionTitle;

SectionTitle.propTypes = {
	title: PropTypes.string.isRequired,
};

import { selectColorByScore } from "../../../../shared/helpers";

/**
* returns data and some configs in the appropriate format for the chartJS library.
*
* @param {Array<string>} labels 'Array with labels'
* @param {Array<number>} data 'Array with data'
* @param {Array<string>} lineColor 'Array with hex colors'
* @return {object} formattedData {labels: Array<string>, ...}
*/
export const data = (labels, data, lineColor) => {
  return {
    labels: labels,
    datasets: [
      {
        label: "",
        data: data,
        borderColor: lineColor,
        segment: {
          borderColor: ctx => ctx && selectColorByScore(ctx.p1.parsed.y)
        }
      },
    ]
  }
};
/* istanbul ignore file */
import React, { useEffect } from 'react';
import { setData } from '../config';
import { SentimentScoreHistory } from './components';
import { StyledConstants } from './constants';
import { useDispatch } from "react-redux";
import { useLoadSentimentData } from '../shared/hooks/useLoadSentimentData';
import { useLoadCwParams } from '../shared/hooks/useLoadCwParams';
import LastSentimentScore from './components/last-sentiment-score/last-sentiment-score';
import { SectionTitle } from '../shared/components';
import { getSSOToken } from "../config";

const CustomerSentiment = () => {
	let token = getSSOToken();
	const dispatch = useDispatch();
	const { companyId, ticketId } = useLoadCwParams();
	const { data, refreshToken } = useLoadSentimentData(companyId, ticketId,token);
    useEffect(() => {
		if (data) {
			dispatch(setData(data));
		}
	}, [data, refreshToken]);

	return (data && 
		<StyledConstants.CustomerSentimentStyle>
			<StyledConstants.SectionsContainerStyle>
				<StyledConstants.HeaderContainerStyle>
					<SectionTitle title="Sentiment score history" />
					<LastSentimentScore />
				</StyledConstants.HeaderContainerStyle>
			<SentimentScoreHistory />
			</StyledConstants.SectionsContainerStyle>
		</StyledConstants.CustomerSentimentStyle>
	);
};

export default CustomerSentiment;
